import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/SEO';
import SectionIntro from '../../../components/SectionIntro';
// Background images
import {getImage} from 'gatsby-plugin-image';
import {useStaticQuery, graphql} from 'gatsby';
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const ApplyContact = () => {
  return (
    <div className="relative flex flex-col px-8 py-8 bg-gray-100 rounded-2xl drop-shadow-lg w-full md:w-[400px] xl:w-[500px]">
      <h2 className="mb-4 text-xl text-left w-[250px] md:w-full font-bold">How to Apply</h2>
      <p className="mb-8 text-lg text-left w-full md:max-w-[440px]">
        Interested candidates are invited to submit their resume, cover letter, and any relevant portfolio/work samples
        to careers@trustle.com.
        <br></br>
        <br></br>
        <b>Please include</b> "Product Marketing Executive Application - [Your Name]" in the subject line.
      </p>
      <a
        className="md:max-w-[250px] bg-gradient-to-r from-[#0091FF] via-[#005799] to-[#002761] hover:from-[#4185F4] hover:via-[#00315E] hover:to-[#00315E] active:bg-[#00549F] text-white   rounded-3xl shadow font-bold md:min-w-max lg:px-12  w-full lg:text-lg transition duration-300 ease-in-out hover:drop-shadow-lg z-10 px-2 py-2 my-3 md:my-0 md:py1 md:mb-0"
        href="mailto: careers@trustle.com?subject=Product%20Marketing%20Executive%20Application%20-%20[Your Name]"
      >
        Apply Here
      </a>
    </div>
  );
};

const Careers = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }

      placeholderImage03: file(relativePath: {eq: "bg/rebrand/bg-main-middle-bottom.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage, placeholderImage03} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const image03 = getImage(placeholderImage03);
  const bgImage03 = convertToBgImage(image03);
  return (
    <Layout seo={<Seo title="We’re Hiring | Trustle" description="Join our amazing team here at Trustle" />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
      >
        <section className="pt-16">
          <div className="max-w-5xl px-5 pt-0 mx-auto text-center text-white">
            <SectionIntro
              hero="Company"
              title="We’re Hiring"
              description="Join our amazing team here at Trustle."
              isPageHeader
            />
          </div>
        </section>

        {/* Section 1 */}
        <section className="relative pt-8 bg-white bg-opacity-20 ">
          <div className="max-w-[1360px] mx-auto text-blue text-center mb-8">
            <div className="flex flex-col justify-between mx-8 lg:items-center md:flex-row">
              <div className="">
                <h2 className="mb-4 text-lg font-bold text-left md:text-xl">Job Title</h2>
                <div className="mb-4 text-2xl font-bold text-left md:text-3xl">VP of Marketing</div>
              </div>
            </div>
          </div>
          <div className="max-w-[1360px] mx-auto text-blue  text-center ">
            <div className="mb-8 md:mb-16">
              <div className="flex flex-col items-end justify-between xl:items-center xl:flex-row ">
                <div className="mx-8 text-blue">
                  <h2 className="mb-8 text-lg font-bold text-left sm:text-xl">Company Overview</h2>
                  <div className={`md:text-lg text-left m-auto text-blue mb-8`}>
                    Trustle is a pioneering company in cloud access management, established to revolutionize the way
                    organizations handle access to sensitive data and resources. Founded in 2019, Trustle’s innovative
                    SaaS platform was designed to enhance security and efficiency in managing access permissions across
                    various cloud platforms, decrease the attack surface, and simplify compliance reporting—all within
                    one centralized, context-based product. At Trustle, we value creativity, collaboration, and the
                    drive to make a significant impact. As a small but powerful team, if you’re passionate about product
                    marketing and eager to shape the future of cybersecurity, we want to hear from you.
                  </div>
                  <h2 className="mb-8 text-lg font-bold text-left sm:text-xl">Job Description</h2>
                  <div className={`md:text-lg text-left m-auto text-blue`}>
                    As The VP Product Marketing, you will be a key player in driving the go-to-market strategy,
                    positioning, and messaging of our products. You and your team will work closely with the product,
                    sales, and engineering teams to develop and execute marketing strategies that effectively
                    communicate our value proposition, engage our target audience, and drive growth. The ideal candidate
                    will have experience across the marketing disciplines (demand gen, product marketing, brand
                    building, PR, etc), have a strategic and data driven approach to marketing, and be willing to roll
                    up their sleeves and be very tactical when needed.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 2 */}
        <section className="bg-[#c2e5ff] bg-opacity-20 py-8 md:py-16  relative">
          <div className="max-w-[1360px] mx-auto text-blue text-center">
            <div className="flex flex-col items-center justify-between mx-8 mb-8 xl:flex-row md:items-start">
              <div className="pt-5 pb-8 text-blue xl:pb-0">
                <h2 className="mb-4 text-lg font-bold text-left sm:text-xl">Key Responsibilities:</h2>
                <div className={`md:text-lg text-left m-auto text-blue`}>
                  <ul className="ml-4 space-y-2 leading-7 list-disc xl:space-y-0 xl:leading-9 md:ml-8">
                    <li>
                      <span className="text-lg font-bold xl:text-xl">Market Research & Analysis: </span>Conduct thorough
                      market research to understand customer needs, market trends, and competitive landscape. Provide
                      insights to guide product development and marketing strategies.
                    </li>
                    <li>
                      <span className="text-lg font-bold text-md xl:text-xl">Go-to-Market Strategy: </span>Develop and
                      execute go-to-market plans for product launches, ensuring alignment across all departments.
                      Identify target segments, craft compelling messaging, and create detailed launch plans. Created
                      demand generation campaigns.{' '}
                    </li>
                    <li>
                      <span className="text-lg font-bold text-md xl:text-xl">Positioning & Messaging: </span>Define
                      product positioning and key messaging that differentiates our products in the market. Create
                      clear, concise, and compelling marketing materials.
                    </li>
                    <li>
                      <span className="text-lg font-bold text-md xl:text-xl">Content Creation: </span>Produce
                      high-quality content including case studies, whitepapers, blog posts, website copy, and sales
                      collateral. Ensure all content aligns with our brand voice and strategy.
                    </li>
                    <li>
                      <span className="text-lg font-bold text-md xl:text-xl">Sales Enablement: </span>Develop sales
                      tools and training materials to support the sales team. Equip them with the knowledge and
                      resources needed to effectively sell our solutions.
                    </li>
                    <li>
                      <span className="text-lg font-bold text-md xl:text-xl">Digital Marketing: </span>Oversee digital
                      marketing initiatives including SEO, SEM, email marketing, and social media campaigns. Monitor
                      performance and optimize strategies to increase engagement and conversions.{' '}
                    </li>
                    <li>
                      <span className="text-lg font-bold text-md xl:text-xl">Customer Advocacy: </span>Build and nurture
                      relationships with customers to develop testimonials, case studies, and referrals. Leverage
                      customer feedback to refine product offerings and marketing tactics.
                    </li>
                    <li>
                      <span className="text-lg font-bold text-md xl:text-xl">Performance Tracking: </span>Establish KPIs
                      and metrics to measure the effectiveness of marketing campaigns. Analyze data and generate reports
                      to track progress and inform decision-making.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
      {/* Section 3 */}
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage03}
        preserveStackingContext
      >
        <section className="relative py-8 bg-white md:py-16 bg-opacity-20 ">
          <div className="max-w-[1360px] mx-auto text-blue sm:pt-8 text-center ">
            <div className="mb-8 md:mb-16">
              <div className="flex flex-col items-end justify-between xl:items-center xl:flex-row ">
                <div className="mx-8 mb-8 text-blue md:mb-0">
                  <h2 className="mb-8 text-lg font-bold text-left sm:text-xl">Qualifications:</h2>
                  <div className={`md:text-lg text-left m-auto text-blue`}>
                    <ul className="ml-4 leading-7 list-disc md:ml-8 xl:leading-9">
                      <li>Bachelor’s degree in Marketing, Business, or related field. MBA is a plus.</li>
                      <li>
                        12+ years of experience in product marketing, demand generation, content generation, branding,
                        and PR. Preferably in the Cyber security space.
                      </li>
                      <li>
                        Strategic and tactical. Comfortable setting marketing strategy and writing marketing content.
                      </li>
                      <li>
                        Proven track record of successful product launches and marketing campaigns in early stage B2B
                        SaaS companies.
                      </li>
                      <li>Comfortable taking technical concepts and translating them for a business audience.</li>
                      <li>Strong understanding of digital marketing channels and tactics.</li>
                      <li>Excellent written and verbal communication skills.</li>
                      <li>Ability to thrive in a fast-paced, dynamic startup environment.</li>
                      <li>
                        Strong analytical skills with the ability to interpret data and draw actionable insights. Data
                        and metrics driven.
                      </li>
                      <li>Collaborative team player with a proactive attitude and strong problem-solving skills.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-[1360px] mx-auto text-blue text-center">
            <div className="flex flex-col items-start justify-between mx-8 mb-8 xl:items-center xl:flex-row md:mb-16">
              <div className="w-full pb-8 lg:pb-0">
                <h2 className="mb-4 text-lg font-bold text-left sm:text-xl">Why Join Us:</h2>
                <div className={`md:text-lg text-left m-auto text-blue mb-8`}>
                  <ul className="ml-4 leading-7 list-disc xl:leading-9 md:ml-8">
                    <li>Opportunity to make a significant impact at an early-stage startup.</li>
                    <li>Collaborative and innovative work environment.</li>
                    <li>Competitive salary and equity package.</li>
                    <li>Flexible working hours and remote work options.</li>
                    <li>Professional growth and development opportunities.</li>
                  </ul>
                </div>
              </div>
              <div className="flex w-full mt-8 xl:justify-end">
                <ApplyContact />
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default Careers;
